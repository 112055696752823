import React from "react"

import { Perex } from "@igloonet-web/shared-ui"
import Layout from "@igloonet-web/shared-ui/components/layout/layout"

const NotFoundPage = (): React.ReactNode => (
  <Layout>
    <Perex>
      <h1>Dyť tu byla!</h1>
      <p>
        Je to polámané a stránka tu není, zkuste menu nahoře, dole, vpravo nebo
        vlevo.
      </p>
    </Perex>
  </Layout>
)

export default NotFoundPage
